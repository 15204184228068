import "./App.css";
import { CometChat } from "@cometchat-pro/chat";
import QueryString from "query-string";
import { useLocation } from "react-router-dom";
import { CometChatUI } from "./cometchat";
import { useEffect, useState } from "react";

export const App = () => {
  const location = useLocation();
  const [loading, setLoading] = useState(true);

  const { token, userId } = QueryString.parse(location.search, { parseBooleans: true });

  useEffect(() => {
    initChat(token);
  }, [token]);

  console.log("test");

  const initChat = async (token) => {
    if (token) {
      await CometChat.init("24018be55c54d57", new CometChat.AppSettingsBuilder().subscribePresenceForAllUsers().setRegion("eu").autoEstablishSocketConnection(false).build());
      const user = await CometChat.getLoggedinUser();
      if (CometChat.getConnectionStatus() === "disconnected") CometChat.connect();
      if (!user) await CometChat.login(token);
      setLoading(false);
    }
  };

  if (loading) return <div>Loading...</div>;

  return (
    <div style={{ width: "800px", height: "800px" }}>
      <CometChatUI chatWithUser={userId || ""} />
    </div>
  );
};
